.App {
  overflow: hidden;
  .App-logo {
    height: 20vmin;
    width: 20vmin;
    pointer-events: none;
    position: absolute;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 40s linear;
    }
    .egg {
      animation: App-logo-spin infinite 10s linear;
    }
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .iconHolder {
    width: 100vw;
    height: 100vh;
    background-color: #060000;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;

    .bounceX {
      position: absolute;
      left: 0;
      top: 0;
      animation: bounceX 18s linear infinite alternate;
    }

    .bounceY {
      position: absolute;
      left: 0;
      top: 0;
      animation: bounceY 12s linear infinite alternate;
    }

    .bounceXegg {
      position: absolute;
      left: 0;
      top: 0;
      animation: bounceX 15s linear infinite alternate;
    }

    .bounceYegg {
      position: absolute;
      left: 0;
      top: 0;
      animation: bounceY 19s linear infinite alternate;
    }

    @keyframes bounceX {
      100% {
        transform: translateX(calc(80vw));
      }
    }
    @keyframes bounceY {
      100% {
        transform: translateY(calc(80vh));
      }
    }
  }
}
